// constants
import { loaderStages } from '@/utils/constants/delayedActionPopup';

// actions
import { submitFormData } from '@/api';
import {
	goToExternalRoute,
	goToRoute,
	isExternalUrl,
	setPaymentState,
} from '@/store/actions';

// utils
import * as eventActions from '@/utils/analytics';
import { getEventProps, getOrderPayload, getPrefillData } from './util';

const createPayment = function (config = {}, dispatch) {
	const trackEvent = (event, paymentProps) => {
		eventActions.trackEvent(event, {}, '', paymentProps);
	};

	const handleModalClose = () => {
		dispatch(setPaymentState(null));
	};

	const onPaymentFailed = async (response) => {
		dispatch(setPaymentState(null));
		dispatch(
			goToRoute(
				config.failureLink || config.thankYouRedirectionLink,
				{},
				{
					status: 0,
					source: config.source,
					razorpay_order_id: response.error.metadata.order_id,
				}
			)
		);
	};

	const onPaymentSuccess = async () => {
		const query = { source: config.source, status: 1, formComplete: true },
			redirectionLink = config.successLink || config.thankYouRedirectionLink;

		if (isExternalUrl(redirectionLink)) {
			goToExternalRoute(redirectionLink, null, {}, query);
			dispatch(setPaymentState(null));
		} else {
			dispatch(goToRoute(redirectionLink, {}, query));
		}
	};

	const verifyPayment = (data, orderDetails) => {
		dispatch(setPaymentState(loaderStages.verifying));

		return submitFormData(config.paymentVerifyAPI, { data })
			.then((result) => {
				if (!result.data) throw new Error(result.errorMessage);

				dispatch(setPaymentState(loaderStages.success));
				trackEvent(
					'purchase',
					getEventProps(orderDetails, data.razorpay_payment_id)
				);
				onPaymentSuccess();
			})
			.catch(() => onPaymentFailed(data));
	};

	const startPayment = () => {
		return submitFormData(config.paymentAPI, getOrderPayload(config)).then(
			({ data: orderDetails }) => {
				// eslint-disable-next-line no-undef
				const rzp = new Razorpay({
					...orderDetails,
					payment_capture: 1,
					modal: { ondismiss: handleModalClose },
					prefill: getPrefillData(config),
					handler: (response) => verifyPayment(response, orderDetails),
				});
				rzp.open();
				rzp.on('payment.failed', onPaymentFailed);

				dispatch(setPaymentState(loaderStages.inProgress));
				trackEvent('add_payment_info', getEventProps(orderDetails));
			}
		);
	};

	return { startPayment };
};

export default createPayment;
