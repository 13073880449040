import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

// constants
import { formTypes } from '@/utils/constants/formTypes';

// hook
import useUserModal from './use-login-modal';

// actions
import { setUserModalData } from '@/store/actions';

// utils
import * as eventActions from '@/utils/analytics';
import {
	applyConfigToForm,
	getDefaultUserValues,
	getDefaultValuesFromSearch,
	getLeadVerifiedEventProps,
	hasRequiredData,
} from '@/utils/form';
import createPayment from '@/utils/payment';
import replaceSearchQueryValues from '@/utils/url/replaceSearchQueryValues';

export default function useFormBoxProps({
	form,
	authUserForm,
	paymentDetail,
	useOtpFlowForForm,
	formPageConfig,
	formPageConstants,
	...props
}) {
	formPageConfig = formPageConfig || {};
	formPageConstants = formPageConstants || {};

	const dispatch = useDispatch();
	const openLoginModal = useUserModal();
	const search = useSelector((state) => state.router.location.search);

	const userData = useSelector((state) => state.user.data);
	const profileLoading = useSelector((state) => state.profile.loading);
	const profileData = useSelector((state) => state.profile.data);
	const userDataRef = React.useRef({});

	// config
	const cmsGlobalData = useSelector((state) => state.cmsGlobalData.data);
	const openPaymentAfterFormSuccess = !!(
		paymentDetail && Object.keys(paymentDetail).length
	);
	const disableSuccessFlowAfterFormSuccess =
		openPaymentAfterFormSuccess || useOtpFlowForForm;

	/** form details after configuration */
	const _form = React.useMemo(() => {
		if ((profileLoading && authUserForm) || !form) return null;

		const useAuthUserForm =
			!profileLoading &&
			!!form &&
			profileData &&
			authUserForm &&
			hasRequiredData(
				form,
				profileData || userDataRef.current,
				formPageConstants
			);

		return applyConfigToForm(
			cloneDeep(useAuthUserForm ? authUserForm : form),
			formPageConfig,
			get(cmsGlobalData, 'globalFormConfig') || {},
			formPageConstants
		);
	}, [profileLoading, profileData]);

	/** field value from search query */
	let defaultFormValues = React.useMemo(() => {
		if (!_form) return {};

		return {
			...getDefaultUserValues(form, profileData || userDataRef.current),
			...getDefaultValuesFromSearch(
				_form,
				Object.fromEntries(new URLSearchParams(search || ''))
			),
		};
	}, [search, profileData]);

	React.useEffect(() => {
		if (!profileData) return;

		userDataRef.current = profileData;
	}, [profileData]);

	if (!_form) return null;

	const leadVerifiedEventProps = getLeadVerifiedEventProps(_form.name);
	const trackEvent = () => {
		eventActions.trackEvent(leadVerifiedEventProps.eventName, {
			...(props.analyticsProps || {}),
			...leadVerifiedEventProps,
		});
	};

	const handlePayment = async (formData) => {
		const paymentConfig = {
			...paymentDetail,
			userDetail: formData,
			source: props.pageSlug || '',
			thankYouRedirectionLink: _form.thankYouRedirectionLink,
			couponCode: formData.couponCode || paymentDetail.couponCode || '',
		};
		const payment = createPayment(paymentConfig, dispatch);

		await payment.startPayment();
	};

	/** open login modal with otp sended */
	const handleLogIn = (formData) => {
		const redirectionLink = replaceSearchQueryValues(
			_form.thankYouRedirectionLink,
			formData
		);

		if (userData.registered) {
			trackEvent();
		} else {
			dispatch(
				setUserModalData({ postSuccessAnalyticsProps: leadVerifiedEventProps })
			);
		}

		openLoginModal('login', formData.mobile, {
			sendOTPToNumber: true,
			redirectionLink,
		});
	};

	let successCallback = () => {
		trackEvent();
	};
	if (openPaymentAfterFormSuccess || useOtpFlowForForm) {
		successCallback = async (_, __, formData) => {
			if (openPaymentAfterFormSuccess) {
				trackEvent();
				await handlePayment(formData);
			} else if (useOtpFlowForForm) {
				handleLogIn(formData);
			}
		};
	}

	return {
		..._form,
		paymentDetail,
		successCallback,
		defaultFormValues,
		formName: _form.name,
		formType: get(paymentDetail, 'withCouponCode')
			? formTypes.paymentForm
			: formTypes.form,
		errorModalDetails: _form.errorModal,
		successModalDetails: _form.successModal,
		pageConstants: formPageConstants,
		disableSuccessModal: disableSuccessFlowAfterFormSuccess,
		disableRedirectAfterSuccess: disableSuccessFlowAfterFormSuccess,
	};
}
